import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  CreatedAt,
  DetailContainer,
  H1,
  Thumbnail,
  FlexWrap,
  CategoryName,
  Column2,
} from "../utils"
import styled from "styled-components"

const Table = styled.table`
  th,
  td {
    padding: ${({ theme }) => theme.spacing[1]};
  }
  thead {
    padding: ${({ theme }) => theme.spacing[1]};
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary};
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.colors.gray[200]};
      }
      th {
        font-family: ${({ theme }) => theme.font.head};
        font-weight: bold;
        text-align: start;
      }
      td {
        font-family: ${({ theme }) => theme.font.mono};
        font-weight: bold;
      }
    }
  }
`
const List = styled.ul`
  li {
    padding: ${({ theme }) => theme.spacing[2]} 0
      ${({ theme }) => theme.spacing[1]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
    span {
      margin-right: ${({ theme }) => theme.spacing[1]};
    }
  }
`

export const pageQuery = graphql`
  query ($slug: String) {
    contentfulRecipes(slug: { eq: $slug }) {
      title
      createdAt(formatString: "YYYY.MM.DD")
      category {
        name
      }
      id
      slug
      thumbnail {
        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5625)
      }
      ingredients {
        id
        name
        value
      }
      instruction {
        id
        content
      }
    }
  }
`

const Recipe = ({ data }) => {
  const { title, createdAt, thumbnail, ingredients, instruction, category } =
    data.contentfulRecipes
  return (
    <DetailContainer>
      <FlexWrap>
        <CategoryName>{category.name}</CategoryName>
        <CreatedAt>{createdAt}</CreatedAt>
      </FlexWrap>
      <H1>{title}</H1>
      <Thumbnail>
        <GatsbyImage image={getImage(thumbnail)}></GatsbyImage>
      </Thumbnail>
      <Column2 mt="4rem">
        <Table>
          <thead>
            <tr>
              <th>材料名</th>
              <td>分量</td>
            </tr>
          </thead>
          <tbody>
            {ingredients.map(node => {
              return (
                <tr key={node.id}>
                  <th>{node.name}</th>
                  <td>{node.value}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <List>
          {instruction.map((node, index) => {
            return (
              <li key={node.id}>
                <span>{index + 1}.</span>
                {node.content}
              </li>
            )
          })}
        </List>
      </Column2>
    </DetailContainer>
  )
}

export default Recipe
